<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHieg">
      <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="200px"
               size="small">
        <el-row>
          <el-col :span="24">
            <div class="minTitle">基本信息</div>
          </el-col>
          <el-col :span="11">
            <el-form-item label="专家名称" prop="expertName">
              <el-input
                  v-model.trim="inputForm.expertName"
                  placeholder="请输入专家名称(限50字)"
                  maxlength="50"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="身份证号" prop="expertCard">
              <el-input
                  v-model.trim="inputForm.expertCard"
                  @blur="setMessage"
                  placeholder="请输入身份证号"
                  maxlength="18"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="出生日期" prop="birthDate">
              <el-input
                  v-model.trim="inputForm.birthDate"
                  placeholder="请输入出生日期"
                  maxlength="18"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="年龄" prop="expertAge">
              <el-input
                  @input="setAge('expertAge')"
                  v-model.trim="inputForm.expertAge"
                  :disabled="inputType"
                  placeholder="年龄"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="性别" prop="expertSex">
              <el-select
                  v-model="inputForm.expertSex"
                  clearable
                  :disabled="inputType"
                  placeholder="请选择性别"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in this.$dictUtils.getDictList('expert_sex')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="民族" prop="expertNation">
              <el-input
                  v-model.trim="inputForm.expertNation"
                  placeholder="请输入民族(限10字)"
                  maxlength="10"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="籍贯" prop="nativePlace">
              <el-input
                  v-model.trim="inputForm.nativePlace"
                  placeholder="请输入籍贯(限50字)"
                  maxlength="50"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="政治面貌" prop="politicalStatus">
              <el-input
                  v-model.trim="inputForm.politicalStatus"
                  placeholder="请输入政治面貌(限10字)"
                  maxlength="10"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="邮箱" prop="expertMailbox">
              <el-input
                  v-model.trim="inputForm.expertMailbox"
                  placeholder="请输入邮箱"
                  maxlength="200"
                  :disabled="inputType"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="联系电话" prop="expertPhone">
              <el-input :disabled="inputType"
                        v-model.number.trim="inputForm.expertPhone"
                        placeholder="请输入联系电话"
                        maxlength="11" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="minTitle">从业经历</div>
          </el-col>
          <el-col :span="22">
            <el-form-item label="鉴定经验" prop="experience">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.experience" maxlength="500"
                        placeholder="请详细描述鉴定经验(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="履职简述" prop="jobDescription">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.jobDescription" maxlength="500"
                        placeholder="请详细描述履职简述(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="minTitle">擅长的领域</div>
          </el-col>
          <el-col :span="22">
            <el-form-item label="专业技能" prop="professionalSkill">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.professionalSkill" maxlength="500"
                        placeholder="请详细描述专业技能以及对应的项目经验(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="minTitle">资质信息</div>
          </el-col>
          <el-col :span="22">
            <el-form-item label="学历背景" prop="educationBackground">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.educationBackground" maxlength="500"
                        placeholder="请详细描述专家的毕业院校、专业、学位、毕业时间等信息(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="获得荣誉" prop="receiveHonor">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.receiveHonor" maxlength="500"
                        placeholder="请详细描述专家获得的荣誉(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="获得资质凭证" prop="qualificationCertificate">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.qualificationCertificate" maxlength="500"
                        placeholder="请详细描述专家获得的资质凭证(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-if="butType == 1 || butType == 4" class="text_center" style="margin-top: 10px">
      <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button size="small" v-if="butType == 4 && this.inputForm.examineState == 1 && see != 1" type="primary"
                 @click="through(3)">通过
      </el-button>
      <el-button size="small" v-if="butType == 4 && this.inputForm.examineState == 1 && see != 1" type="danger"
                 @click="through(2)">驳回
      </el-button>
      <el-button size="small" @click=returnPage(1)>关闭</el-button>
    </div>
    <div v-else class="text_center" style="margin-top: 10px">
      <el-button size="small" @click=returnPage(1)>取消</el-button>
      <el-button type="primary" size="small" v-no-more-click @click="submitTo(0)">保存为草稿</el-button>
      <el-button type="primary" size="small" v-no-more-click @click="submitTo(1)">提交</el-button>
    </div>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage()"></reviewSuggestions>
  </div>
</template>

<script>
import validator from "@/utils/validator";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
  name: "addAppraisal",
  components: {reviewSuggestions},
  data() {
    var isEmail = (rule, value, callback) => {
      if (value) {
        const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const card = reg.test(value);
        if (!card) {
          callback(new Error("邮箱格式有误！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
      callback();
    };

    //身份证号格式校验
    var isCardId = (rule, value, callback) => {
      if (value) {
        const reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);

        let idYear = value.substring(6, 10)
        let nowYear = new Date()
        let card2
        if (nowYear.getFullYear() < idYear) {
          card2 = false
        } else if (nowYear.getFullYear() - idYear > 124) {
          card2 = false
        } else {
          card2 = true
        }
        if (!card || !card2) {
          callback(new Error("身份证号格式有误！"));
        } else {
          callback();
        }


      } else {
        callback();
      }
      callback();
    };
    return {
      expertId: '',
      inputForm: {
        expertName: '',
        expertCard: '',
        birthDate: '',
        expertAge: '',
        expertSex: '',
        expertNation: '',
        nativePlace: '',
        politicalStatus: '',
        expertMailbox: '',
        expertPhone: '',
        experience: '',
        jobDescription: '',
        educationBackground: '',
        receiveHonor: '',
        qualificationCertificate: '',
      },
      rules: {
        expertName: [
          {required: true, message: "请填写专家名称", trigger: "blur"},
        ],
        expertCard: [{validator: isCardId, trigger: "blur"}
        ],
        expertMailbox: [
          {validator: isEmail, message: '请输入正确的邮箱', trigger: 'blur'}
        ],
        expertPhone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {validator: validator.isMobile, message: '请输入正确的联系电话', trigger: 'blur'}
        ],
      },
      registrationSearch: {},
      inputType: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 4审核
      see: false,
    }
  },

  mounted() {
    this.expertId = this.$route.query.expertId
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    if (this.butType == 1 || this.butType == 4) {
      this.inputType = true
    }
    if (this.expertId) {
      this.getDataDetail()
    }

    this.setPageTitle()
  },

  methods: {
    getDataDetail() {
      this.$axios(this.api.collection.appraisalexpertGetById + this.expertId, {}, 'get').then((res) => {
        if (res.status) {
          this.inputForm = res.data
        }
      })
    },

    // 0保存 1提交
    submitTo(num) {
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          let fechUrl = this.api.collection.appraisalexpertSave
          let methout = 'post'
          if (this.expertId) {
            fechUrl = this.api.collection.appraisalexpertById
            methout = 'put'
          }
          this.$axios(fechUrl, {
            ...this.inputForm,
            examineState: num,
          }, methout).then(data => {
            if (data.status) {
              this.$message.success(`${num == 0 ? '保存' : '提交'}成功！`)
              this.programExit = true
              this.returnPage(1)
            } else {
              this.$message.error(data.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current == 1) {
          this.$message.error('已是第一条')
        } else {
          this.registrationSearch.current--
        }
      }
      this.$axios(this.api.collection.appraisalexpertList, this.registrationSearch, 'get').then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.expertId = res.data.records[0].expertId
            this.getDataDetail()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      this.$set(this.inputForm, 'id', this.expertId)
      this.$refs.suggestions.init(2, [this.inputForm], type, 9, this.api.collection.appraisalexpertExamine)
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    setMessage() {
      const reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
      const card = reg.test(this.inputForm.expertCard);
      let idYear = this.inputForm.expertCard.substring(6, 10)
      let nowYear = new Date()
      let card2 = false
      if (nowYear.getFullYear() < idYear) {
        card2 = false
      } else if (nowYear.getFullYear() - idYear > 124) {
        card2 = false
      } else {
        card2 = true
      }
      if (card && card2) {
        if (!this.inputForm.birthDate) {
          this.inputForm.birthDate = this.inputForm.expertCard.substring(6, 14)
        }

        if (!this.inputForm.expertAge) {
          let ageYear = this.inputForm.expertCard.substring(6, 10)
          this.inputForm.expertAge = nowYear.getFullYear() - ageYear
        }
      }
    },

    setAge(age) {
      this.inputForm[age] = this.inputForm[age].replace(/[^0-9.]/g, '')
      if (this.inputForm[age] > 999) {
        this.inputForm[age] = 999
      }
    },

    setPageTitle() {
      let name = '藏品鉴定专家详情'
      if (this.butType == 0) {
        name = '藏品鉴定专家新增'
      } else if (this.butType == 2) {
        name = '藏品鉴定专家修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus) {
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
              })
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
}

.pageHieg {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>
